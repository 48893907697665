const destinationsData = {
  europeDestinations: [
    {
      city: "Barcelona,",
      country: "Spain",
      img: "https://images.ctfassets.net/zmjc9gr9hbbf/wyeZtor2wM0xw9xT6l7Jv/b6b757fd621182ac74324efd99d98d51/CAT35510_Colourful_walls_and_buildings_in_park_Guell__Barcelona._Shutterstock.jpg?w=1980",
      visits: 212,
      reviews: 4.7,
    },
    {
      city: "Saranda,",
      country: "Albania",
      img: "https://albaniamyway.com/wp-content/uploads/2020/08/ksamil-beach-saranda-albania.jpg",
      visits: 148,
      reviews: 4.8,
    },
    {
      city: "Santorini,",
      country: "Grecce",
      img: "https://cf.bstatic.com/xdata/images/hotel/max1024x768/88542794.jpg?k=3bbf83f185d7ffed136d7e12636aeff36c21b156760a9d5ed158c62ffbc9636a&o=&hp=1",
      visits: 278,
      reviews: 4.9,
    },
    {
      city: "Paris,",
      country: "France",
      img: "https://images.adsttc.com/media/images/5d44/14fa/284d/d1fd/3a00/003d/large_jpg/eiffel-tower-in-paris-151-medium.jpg?1564742900",
      visits: 238,
      reviews: 4.6,
    },
    {
      city: "Venice,",
      country: "France",
      img: "https://lp-cms-production.imgix.net/2021-06/GettyRF_543346423.jpg",
      visits: 238,
      reviews: 4.6,
    },
    {
      city: "Mykonos,",
      country: "Greece",
      img: "https://www.weloveourlife.net/wp-content/uploads/2021/08/A7R04065-1-1440x810.jpg",
      visits: 188,
      reviews: 4.8,
    },
    {
      city: "Amsterdam,",
      country: "Netherlands",
      img: "https://tulipfestivalamsterdam.com/wp-content/uploads/2018/10/Amsterdam-flowers-bigstock-Amsterdam-City-Skyline-At-Cana-228660700-1024x683.jpg",
      visits: 111,
      reviews: 4.3,
    },
    {
      city: "Menorca,",
      country: "Spain",
      img: "https://www.swedishnomad.com/wp-content/images/2020/10/Things-to-do-in-Menorca.jpg",
      visits: 118,
      reviews: 4.5,
    },
  ],
  asiaDestinations: [
    {
      city: "",
      country: "Maldives",
      img: "https://gommts3.mmtcdn.com/htl-imgs/htl-imgs/202001291805159657-303c1a80_z.jpg",
      visits: 318,
      reviews: 4.9,
    },
    {
      city: "Dubai,",
      country: "UAE",
      img: "https://www.foyerglobalhealth.com/wp-content/uploads/2022/04/Als-Expat-in-Dubai-Alle-unsere-Tipps-fur-eine-erfolgreiche-Auswanderung-1568x1046.jpg",
      visits: 391,
      reviews: 4.9,
      link: "/dubai",
    },
    {
      city: "Bali,",
      country: "Indonesia",
      img: "https://a.cdn-hotels.com/gdcs/production143/d1112/c4fedab1-4041-4db5-9245-97439472cf2c.jpg",
      visits: 304,
      reviews: 4.8,
    },
    {
      city: "Tokyo,",
      country: "Japan",
      img: "https://media.gq-magazine.co.uk/photos/5d13ad779a22c269d69496c8/16:9/w_2560%2Cc_limit/tokyo-hp-gq-19oct18_istock_b.jpg",
      visits: 274,
      reviews: 4.5,
    },
    {
      city: "Phuket,",
      country: "Thailand",
      img: "https://www.letsphuket.com/wp-content/uploads/phuket1.jpg",
      visits: 210,
      reviews: 4.7,
    },
    {
      city: "Hanoi,",
      country: "Vietnam",
      img: "https://a.cdn-hotels.com/gdcs/production64/d444/5252610b-aea3-4ea6-a76e-1ede20547e94.jpg",
      visits: 82,
      reviews: 4.4,
    },
    {
      city: "",
      country: "Singapore",
      img: "https://cdn.tripways.com/2020/04/01121701/singapore-2393073_640.jpg",
      visits: 172,
      reviews: 4.5,
    },
  ],
  northAmericaDestinations: [
    {
      city: "Miami,",
      country: "Florida, USA",
      img: "https://ar.happyvalentinesday2020.online/pics/a.travel-assets.com/findyours-php/viewfinder/images/res70/339000/339657-Downtown-Miami.jpg",
      visits: 248,
      reviews: 4.7,
    },
    {
      city: "",
      country: "Belize",
      img: "https://www.atastefortravel.ca/wp-content/uploads/2022/04/South-Water-Caye-DP-scaled-e1649416441277.jpg",
      visits: 48,
      reviews: 4.4,
    },
    {
      city: "LA,",
      country: "California, USA",
      img: "https://images.skypicker.com/?image=https%3A%2F%2Fimages.kiwi.com%2Fphotos%2Foriginals%2Flos-angeles_ca_us.jpg&width=992&height=600&quality=80&fit=crop&format=original",
      visits: 381,
      reviews: 4.8,
    },
    {
      city: "Tulum,",
      country: "Mexico",
      img: "https://destinationlesstravel.com/wp-content/uploads/2017/11/tulummexico-beach.jpg.webp",
      visits: 104,
      reviews: 4,
    },
    {
      city: "KeyWest,",
      country: "Florida, USA",
      img: "https://keywest.com/img/home-photo-4.jpg",
      visits: 108,
      reviews: 4,
    },
    {
      city: "NYC,",
      country: "New York State, USA",
      img: "https://donstravels.com/wp-content/uploads/2023/01/gettyimages-688899881-1519413300.jpeg",
      visits: 372,
      reviews: 4.7,
    },

    {
      city: "LasVegas,",
      country: "Texas, USA",
      img: "https://www.thestreet.com/.image/t_share/MTg3NjE5NzU0MTY3NTEwMjcx/las-vegas-strip.jpg",
      visits: 123,
      reviews: 4.6,
    },
  ],
  otherContinentsDestinations: [
    {
      city: "Sydney,",
      country: "Australia",
      img: "https://www.mensjournal.com/.image/t_share/MTk2MTM2ODQ5MDcxNDE2ODM3/sydney-main.jpg",
      visits: 221,
      reviews: 4.7,
    },
    {
      city: "",
      country: "Samoa",
      img: "https://cdn.prod.www.spiegel.de/images/dc54d8e0-0001-0004-0000-000000020302_w996_r1.778_fpx47.05_fpy50.jpg",
      visits: 73,
      reviews: 4.7,
    },
    {
      city: "SaoPaulo,",
      country: "Brazil",
      img: "https://singularityhub.com/wp-content/uploads/2018/04/sao-paulo-octavio-frias-de-oliveira-bridge-brazil-latin-america-153080222.jpg",
      visits: 173,
      reviews: 4.7,
    },
    {
      city: "Paracas,",
      country: "Peru",
      img: "https://kittiaroundtheworld.com/wp-content/uploads/2023/01/Paracas-National-Reserve-Playa-Roja.jpg",
      visits: 13,
      reviews: 4.2,
    },
    {
      city: "CapeTown,",
      country: "South Africa",
      img: "https://resources.formula-e.pulselive.com/photo-resources/2023/02/20/042dd057-7157-4069-beb8-2aa3af8b21ba/Cape-Town.jpg?width=1440&height=810",
      visits: 73,
      reviews: 4.5,
    },
    {
      city: "BuenosAires,",
      country: "Argentina",
      img: "https://marvel-b1-cdn.bc0a.com/f00000000234031/www.pacificu.edu/sites/default/files/Images/editors/817/buenos%20aires.jpg",
      visits: 43,
      reviews: 4.3,
    },
  ],
};

export default destinationsData;
